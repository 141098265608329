var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center white--text",staticStyle:{"margin-top":"0px !important"}},[_c('div',{staticClass:"d-flex justify-space-between mb-5"},[_c('div',{staticClass:"text-h6 font-weight-bold",class:_vm.$vuetify.theme.dark ? 'white--text' : 'black--text'},[_vm._v("Tier")]),_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{on:{"click":function($event){return _vm.slider.prev()}}},[_vm._v("mdi-arrow-left-circle-outline")]),_c('v-icon',{on:{"click":function($event){return _vm.slider.next()}}},[_vm._v(" mdi-arrow-right-circle-outline")])],1)]),_c('div',{ref:"slider",staticClass:"keen-slider",class:{
      'img-css': !_vm.$vuetify.theme.dark,
    },staticStyle:{"border-radius":"16px"}},_vm._l((_vm.indicatorHelper),function(item,index){return _c('div',{key:index,staticClass:"keen-slider__slide d-flex flex-column align-center golden-border-tier",staticStyle:{"width":"100% !important"}},[_c('v-responsive',{staticClass:"border-radius-16 carousel-responsive-frame",class:{
          'carousel-bg-dark': _vm.$vuetify.theme.dark,
          'carousel-bg-light': !_vm.$vuetify.theme.dark,
        },attrs:{"aspect-ratio":1.5}},[(item.currectTier)?_c('div',{staticClass:"your-tier pr-2 pl-1",attrs:{"small":"","text-color":"white"}},[_c('v-icon',{attrs:{"left":"","size":"12"}},[_vm._v(" mdi-star ")]),_c('span',{staticClass:"font-weight-medium text-caption"},[_vm._v("Your Tier")])],1):_vm._e(),_c('div',{staticClass:"d-flex carousel-tier-img",class:{
            'tier-image-md-xs': !(_vm.$vuetify.breakpoint.mdAndDown && _vm.$vuetify.breakpoint.smAndUp),
            'tier-image-sm': _vm.$vuetify.breakpoint.mdAndDown && _vm.$vuetify.breakpoint.smAndUp,
          }},[_c('v-img',{attrs:{"src":item.image,"contain":""}})],1),_c('div',{staticClass:"pa-6 carousel-waggle-logo transparent-bg"},[_c('v-img',{attrs:{"src":require("@/assets/waggle-logo.dark.svg"),"max-width":79,"contain":""}})],1),_c('div',{staticClass:"text-start pa-6 carousel-text-info transparent-bg"},[_c('div',{staticClass:"font-weight-bold text-body-2 linear-color-text"},[_vm._v(_vm._s(item.name))]),_c('div',{class:{
              'd-flex align-center ': _vm.$vuetify.breakpoint.smAndUp,
              'f-flex flex-column': !_vm.$vuetify.breakpoint.smAndUp,
            }},[_c('div',{staticClass:"text-caption font-weight-bold mr-2"},[_vm._v(_vm._s(_vm._f("formatNumber")(item.amount,0, 0))+" $WAG")]),(index >= 2)?_c('div',{staticClass:"text-caption",class:_vm.$vuetify.theme.dark ? 'grey--text' : 'white--text'},[_vm._v(" *Guaranteed Whitelist* ")]):_vm._e()])]),_c('div',{staticClass:"carousel-frame-absolute carousel-bg-1"}),(_vm.$vuetify.theme.dark)?_c('div',{staticClass:"carousel-frame-absolute",class:{
            'carousel-bg-tier-1': index === 0,
            'carousel-bg-tier-2': index === 1,
            'carousel-bg-tier-3': index === 2,
            'carousel-bg-tier-4': index === 3,
          }}):_vm._e()])],1)}),0),(_vm.slider)?_c('div',{staticClass:"indicators"},_vm._l((_vm.indicatorHelper),function(slide,idx){return _c('button',{key:idx,class:{ indicator: true, active: _vm.current === idx },on:{"click":function($event){return _vm.slider.moveToSlideRelative(idx)}}})}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }