<template>
  <div class="text-center white--text" style="margin-top: 0px !important">
    <div class="d-flex justify-space-between mb-5">
      <div class="text-h6 font-weight-bold" :class="$vuetify.theme.dark ? 'white--text' : 'black--text'">Tier</div>
      <div class="d-flex align-center">
        <v-icon @click="slider.prev()">mdi-arrow-left-circle-outline</v-icon>
        <v-icon @click="slider.next()"> mdi-arrow-right-circle-outline</v-icon>
      </div>
    </div>
    <div
      ref="slider"
      class="keen-slider"
      style="border-radius: 16px"
      :class="{
        'img-css': !$vuetify.theme.dark,
      }"
    >
      <div
        class="keen-slider__slide d-flex flex-column align-center golden-border-tier"
        style="width: 100% !important"
        v-for="(item, index) in indicatorHelper"
        :key="index"
      >
        <v-responsive
          :aspect-ratio="1.5"
          class="border-radius-16 carousel-responsive-frame"
          :class="{
            'carousel-bg-dark': $vuetify.theme.dark,
            'carousel-bg-light': !$vuetify.theme.dark,
          }"
        >
          <div v-if="item.currectTier" class="your-tier pr-2 pl-1" small text-color="white">
            <v-icon left size="12"> mdi-star </v-icon>
            <span class="font-weight-medium text-caption">Your Tier</span>
          </div>

          <div
            class="d-flex carousel-tier-img"
            :class="{
              'tier-image-md-xs': !($vuetify.breakpoint.mdAndDown && $vuetify.breakpoint.smAndUp),
              'tier-image-sm': $vuetify.breakpoint.mdAndDown && $vuetify.breakpoint.smAndUp,
            }"
          >
            <v-img :src="item.image" contain />
          </div>
          <div class="pa-6 carousel-waggle-logo transparent-bg">
            <v-img :src="require(`@/assets/waggle-logo.dark.svg`)" :max-width="79" contain />
          </div>
          <div class="text-start pa-6 carousel-text-info transparent-bg">
            <div class="font-weight-bold text-body-2 linear-color-text">{{ item.name }}</div>
            <div
              :class="{
                'd-flex align-center ': $vuetify.breakpoint.smAndUp,
                'f-flex flex-column': !$vuetify.breakpoint.smAndUp,
              }"
            >
              <div class="text-caption font-weight-bold mr-2">{{ item.amount | formatNumber(0, 0) }} $WAG</div>
              <div class="text-caption" :class="$vuetify.theme.dark ? 'grey--text' : 'white--text'" v-if="index >= 2">
                *Guaranteed Whitelist*
              </div>
            </div>
          </div>
          <div class="carousel-frame-absolute carousel-bg-1" />
          <div
            class="carousel-frame-absolute"
            v-if="$vuetify.theme.dark"
            :class="{
              'carousel-bg-tier-1': index === 0,
              'carousel-bg-tier-2': index === 1,
              'carousel-bg-tier-3': index === 2,
              'carousel-bg-tier-4': index === 3,
            }"
          />
        </v-responsive>
      </div>
    </div>
    <div v-if="slider" class="indicators">
      <button
        v-for="(slide, idx) in indicatorHelper"
        @click="slider.moveToSlideRelative(idx)"
        :class="{ indicator: true, active: current === idx }"
        :key="idx"
      ></button>
    </div>
  </div>
</template>

<script>
import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'
import { tierHelper } from '@/constants'

export default {
  name: 'Slider',
  computed: {
    indicatorHelper() {
      const lst = tierHelper.getTierList()
      for (const tier of [...lst].reverse()) {
        if (this.stakedAmount >= tier.amount) {
          tier.currectTier = true
          break
        }
      }
      return lst
    },
  },
  data() {
    return {
      current: 3,
      slider: null,
    }
  },
  props: {
    stakedAmount: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.slider = new KeenSlider(this.$refs.slider, {
      initial: this.current,
      slideChanged: (s) => {
        this.current = s.details().relativeSlide
      },
    })
  },
  beforeDestroy() {
    if (this.slider) this.slider.destroy()
  },
  watch: {
    stakedAmount() {
      const index = this.indicatorHelper.findIndex((x) => x.currectTier)
      if (index >= 0) this.slider.moveToSlide(index, 1000)
    },
  },
}
</script>
<style lang="scss">
.indicators {
  display: flex;
  padding: 24px 0 0 0;
  justify-content: center;
}

.indicator {
  border: none;
  width: 22px;
  height: 4.3px;
  background: var(--v-grey1-base);
  border-radius: 8px;
  margin: 0 5px;
  /* padding: 5px; */
  cursor: pointer;
}

.indicator:focus {
  outline: none;
}

.indicator.active {
  background: var(--v-primary-base);
  width: 41px;
}

.img-css {
  box-shadow: 10px 0px 10px 0px #ccc;
}
.carousel-waggle-logo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}
.carousel-responsive-frame {
  width: 100% !important;

  position: relative;
}
.carousel-bg-dark {
  background: #131313;
}
.carousel-bg-light {
  background: url('./../../../assets/images/staking-bg.svg');
  background-size: cover;
}
.golden-border-tier {
  background-image: linear-gradient(
    to right,
    #f4af00,
    #f6bd3f,
    #f8ca64,
    #fad786,
    #fce3a7,
    #fbe2a7,
    #fae2a6,
    #f9e1a6,
    #f5d384,
    #f1c462,
    #edb53d,
    #e9a600
  );
  padding: 1px;
  border-radius: 16px !important;
}
.tier-image-md-xs {
  max-width: 60%;
  max-height: 170px !important;
}
.tier-image-sm {
  max-width: 60%;
}
.carousel-frame-absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0 !important;
}
.carousel-text-info {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 99;
}
.carousel-bg-2 {
  opacity: 0.5;
  background-size: cover;
  z-index: 1 !important;
}
.carousel-tier-img {
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 99;
}
.carousel-bg-tier-1 {
  @extend .carousel-bg-2;
  background-image: url('./../../../assets/images/staking-bg-tier-1.png');
}
.carousel-bg-tier-2 {
  @extend .carousel-bg-2;
  background-image: url('./../../../assets/images/staking-bg-tier-2.png');
}
.carousel-bg-tier-3 {
  @extend .carousel-bg-2;
  background-image: url('./../../../assets/images/staking-bg-tier-3.png');
}
.carousel-bg-tier-4 {
  @extend .carousel-bg-2;
  background-image: url('./../../../assets/images/staking-bg-tier-4.png');
  opacity: 0.1 !important;
}
.carousel-bg-1 {
  background-image: url('./../../../assets/images/staking-material.png');
  opacity: 1;
  background-size: cover;
  z-index: 100;
}

.large-text {
  font-size: 28px;
}
.your-tier {
  position: absolute;
  top: 0px;
  left: 0px;
  background: orange;
  border-radius: 0px 0px 14px 0px;
}
</style>
